import { graphql } from '@/api/gql'

export const getAuthProvider = graphql(`
  query getAuthProvider($email: String!) {
    data: getAuthProvider(email: $email) {
      providerUrl
      error {
        code
        message
      }
    }
  }
`)
